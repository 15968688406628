.prices {
    width: 100%;
    margin:2rem 0;
    border-collapse: collapse;

    th {
        color: @color_0;
        font-weight: 900;
        padding: 1rem;
        vertical-align: top;
        small {
            display: block;
            opacity: 0.5;
            font-weight: normal;
            padding-top: 0.25em;
        }
    }
    td {
        text-align: center;
        width: 20%;
        padding: 0.5rem;
        &:first-child {
            width: 40%;
            text-align: left;
            font-weight: 900;
        }
    }
}