.additional {
  display: none;
}

@media screen and (width >= 700px) {
  .additional {
    display: initial;
  }
}

.bold {
  letter-spacing: .02em;
  font-weight: 900;
}

.narrow {
  letter-spacing: -.04em;
}

body, html {
  color: #fff;
  background: #000;
  font-family: Lato, sans-serif;
  font-size: 2vw;
}

@media screen and (width >= 1200px) {
  body, html {
    font-size: 1200px / 100 * 2vw;
  }
}

body {
  max-width: 1200px;
}

.text {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pointer {
  cursor: pointer;
}

.noselect {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  outline: none;
}

.section-divider {
  background-image: url("tri.dc77bb9a.svg");
  background-size: cover 20px;
  height: 20px;
}

a, a:active, a:visited, a:link {
  color: #fff;
}

.section {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 80px 0;
  padding: 20px 1rem;
  position: relative;
}

.section.white {
  color: #000;
  background: #fff;
}

.section.white h1, .section.white h2 {
  color: #330136;
}

.section.white:before {
  background-image: url("tri.dc77bb9a.svg");
  background-size: cover 20px;
  content: "";
  pointer-events: none;
  width: 100%;
  height: 20px;
  position: absolute;
  top: -20px;
  left: 0;
  transform: scaleY(-1);
}

.section.white:after {
  background-image: url("tri.dc77bb9a.svg");
  background-size: cover 20px;
  content: "";
  pointer-events: none;
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.section.black {
  color: #f5f5f5;
  background: #000;
}

.section.black b {
  color: #ff5e35;
  font-weight: normal;
}

.section.black i {
  color: #fc5474;
  font-style: normal;
  font-weight: normal;
}

.section.header {
  background-image: url("tri_bg.af69697c.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 15rem;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}

.section.header + .section.white, .section.header + .section.black {
  margin-top: 0;
}

.logo {
  width: 35vw;
  position: absolute;
}

.address {
  text-align: right;
  opacity: .8;
  padding: 1rem;
  font-size: .8rem;
  line-height: 1.4em;
  position: absolute;
  top: 0;
  right: 0;
}

.address a, .address span {
  display: block;
}

.icon {
  cursor: pointer;
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 70px;
  transition: transform .2s;
  position: absolute;
  bottom: 0;
}

.icon:after {
  text-align: center;
  color: #330136;
  width: 140px;
  max-width: 140px;
  margin-left: -70px;
  font-size: .6rem;
  font-weight: 900;
  position: absolute;
  bottom: -1.4em;
  left: 50%;
}

.icon:hover {
  transform: translateY(-.25rem);
}

.insta {
  cursor: pointer;
  z-index: 2;
  background-image: url("insta_icon.0e995f9b.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 70px;
  transition: transform .2s;
  position: absolute;
  bottom: 0;
  right: 1rem;
}

.insta:after {
  text-align: center;
  color: #330136;
  width: 140px;
  max-width: 140px;
  margin-left: -70px;
  font-size: .6rem;
  font-weight: 900;
  position: absolute;
  bottom: -1.4em;
  left: 50%;
}

.insta:hover {
  transform: translateY(-.25rem);
}

.insta:after {
  content: "Instagram";
}

.facebook {
  cursor: pointer;
  z-index: 2;
  background-image: url("facebook_icon.48857e68.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 70px;
  transition: transform .2s;
  position: absolute;
  bottom: 0;
  right: calc(2rem + 70px);
}

.facebook:after {
  text-align: center;
  color: #330136;
  width: 140px;
  max-width: 140px;
  margin-left: -70px;
  font-size: .6rem;
  font-weight: 900;
  position: absolute;
  bottom: -1.4em;
  left: 50%;
}

.facebook:hover {
  transform: translateY(-.25rem);
}

.facebook:after {
  content: "Facebook";
}

h1, h2 {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  outline: none;
}

h1 {
  letter-spacing: .02em;
  text-align: center;
  margin: 0;
  padding: 0 0 .25rem;
  font-size: 2rem;
  font-weight: 900;
}

h2 {
  text-align: center;
  letter-spacing: -.04em;
  opacity: .7;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
}

.info {
  padding-bottom: 1rem;
  font-size: .8rem;
  line-height: 1.4em;
}

.legend {
  margin-bottom: 1rem;
}

.legend span {
  font-weight: bold;
}

.legend span.fade {
  opacity: .15;
  font-weight: normal;
}

.poster {
  width: 80%;
  margin-left: 10%;
}

.column-text {
  column-count: 2;
  padding: .25rem 0;
  font-size: 1rem;
  line-height: 1.4em;
}

@media screen and (width <= 400px) {
  .column-text {
    column-count: 1;
  }
}

.column-text p {
  margin-top: 0;
  margin-bottom: 1.4rem;
}

table.timetable {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
  margin: 40px 0;
  font-size: 1rem;
}

table.timetable th {
  color: #330136;
  width: 100 / 7%;
  padding: 1rem;
  font-weight: 900;
}

table.timetable tbody {
  font-size: .8rem;
}

table.timetable tbody tr:nth-child(odd) {
  background: #3301361a;
}

table.timetable tbody td {
  width: 100 / 7%;
  max-width: 100 / 7%;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  vertical-align: top;
  cursor: default;
  padding: .3rem .2rem;
  position: relative;
}

table.timetable tbody td .pseudo {
  color: #fff;
  z-index: 2;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: .8rem;
  font-weight: 900;
  position: absolute;
  top: -.25rem;
  right: 0;
  transform: rotate(20deg);
}

table.timetable tbody td.neu .name:after {
  color: #fff;
  z-index: 2;
  content: "Neu";
  text-transform: none;
  background: #71d54ccc;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: .8rem;
  font-weight: 900;
  position: absolute;
  top: -.25rem;
  right: 0;
  transform: rotate(20deg);
}

table.timetable tbody td.plan .time:after {
  color: #962d41;
  z-index: 2;
  content: "In Planung";
  background: #efe14bcc;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: .8rem;
  font-weight: 900;
  position: absolute;
  top: -.25rem;
  right: 0;
  transform: rotate(10deg);
}

table.timetable tbody td[level]:after {
  color: #000;
  z-index: 2;
  top: -.25rem;
  top: unset;
  content: attr(level);
  text-transform: uppercase;
  opacity: .25;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: .5rem;
  font-weight: 900;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: none;
}

table.timetable tbody td:hover {
  background: #3301360d;
}

table.timetable tbody .time, table.timetable tbody .level {
  color: #962d41;
  letter-spacing: -.04em;
  padding: .2rem 0;
}

table.timetable tbody .age {
  opacity: .25;
  font-size: .7em;
}

table.timetable tbody .name {
  text-transform: uppercase;
  letter-spacing: .02em;
  font-weight: 900;
}

.teacher {
  margin-top: 3rem;
}

.teacher:after {
  content: "";
  clear: both;
  display: table;
}

.teacher h1 {
  text-align: left;
  color: #e82c2e;
  font-size: 1.4rem;
  position: relative;
}

.teacher h1:after {
  content: "";
  background: #cb1618;
  width: 60%;
  height: 2px;
  margin-left: -50%;
  position: absolute;
  bottom: -.25rem;
  left: 90%;
}

.teacher img {
  float: left;
  width: 40%;
  margin-right: 2rem;
}

.teacher img.portrait {
  width: 25%;
}

.teacher .description {
  color: #f5f5f5;
  text-align: left;
  font-size: 1rem;
  line-height: 1.4em;
}

.teacher:nth-of-type(2n) img {
  float: right;
}

.teacher:nth-of-type(2n) h1 {
  color: #fe7d7e;
}

.teacher:nth-of-type(2n) h1:after {
  background: #fe7d7e;
  margin-left: 0;
  left: 0;
}

.prices {
  border-collapse: collapse;
  width: 100%;
  margin: 2rem 0;
}

.prices th {
  color: #330136;
  vertical-align: top;
  padding: 1rem;
  font-weight: 900;
}

.prices th small {
  opacity: .5;
  padding-top: .25em;
  font-weight: normal;
  display: block;
}

.prices td {
  text-align: center;
  width: 20%;
  padding: .5rem;
}

.prices td:first-child {
  text-align: left;
  width: 40%;
  font-weight: 900;
}

.gallery {
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem 1rem;
  display: flex;
}

.gallery img {
  cursor: pointer;
  border-bottom: .25rem solid #ff5e35;
  width: 300px;
  height: 200px;
  margin: 1rem;
}

.dialog {
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: background-color .25s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.dialog div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 90%;
  height: 90%;
  transition: all .5s;
  transform: scale(.95);
}

.dialog.onscreen {
  opacity: 1;
  pointer-events: auto;
  background: #000c;
}

.dialog.onscreen div {
  pointer-events: auto;
  transform: translateY(0);
}

.imprint {
  text-align: center;
  white-space: pre-line;
}

.imprint a {
  color: #000;
}

.haftung {
  text-align: left;
  column-count: 2;
  opacity: .5;
  margin-top: 1rem;
  font-size: .6rem;
}

video {
  width: 100%;
}

small {
  opacity: .25;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

:is([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

/*# sourceMappingURL=index.b8d67206.css.map */
