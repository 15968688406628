.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 1rem;

    img {
        width: 300px;
        height: 200px;
        border-bottom: 0.25rem solid @color_4;
        margin: 1rem;
        cursor: pointer;
    }
}