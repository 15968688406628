@red: #cb1618;
.teacher {
  margin-top: 3rem;
  &:after {
    content: "";
    clear: both;
    display: table;
  }

  h1 {
    text-align: left;
    color: lighten(@red, 10%);
    font-size: 1.4rem;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -0.25rem;
      left: 90%;
      width: 60%;
      margin-left: -50%;
      height: 2px;
      background: @red;
    }
  }
  img {
    float: left;
    width: 40%;
    margin-right: 2rem;

    &.portrait {
      width: 25%;
    }
  }
  .description {
    color: #f5f5f5;
    text-align: left;
    font-size: 1rem;
    line-height: 1.4em;
  }

  &:nth-of-type(2n) {
    img {
      float: right;
    }
    h1 {
      color: #fe7d7e;
    }
    h1:after {
      left: 0;
      background: #fe7d7e;
      margin-left: 0;
    }
  }
}
