.dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: background-color 0.25s;

    div {
        width: 90%;
        height: 90%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: scale(0.95);
        transition: all 0.5s;
    }

    &.onscreen {
        opacity: 1;
        pointer-events: auto;
        background: rgba(0,0,0,0.8);

        div {
            pointer-events: auto;
            transform: translateY(0vh);
        }
    }


}