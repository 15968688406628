@color_0: #330136;
@color_1: #5d1642;
@color_2: #962d41;
@color_3: #c8463c;
@color_4: #ff5e35;
@color_high_1: #fc5474;
@color_high_2: #71d54c;
@color_high_3: #efe14b;

@violett: @color_0;

.minWidth(@width, @rules) {
  @media screen and (min-width: @width) {
    @rules();
  }
}
.maxWidth(@width, @rules) {
  @media screen and (max-width: @width) {
    @rules();
  }
}

@maxWidth: 1200px;
@stopTablet: 700px;
@stopPhone: 400px;
@fontSize: 2vw;
@maxFont: @maxWidth / 100 * @fontSize;

.additional {
  display: none;
  .minWidth(@stopTablet, {display: initial;});
}

.bold {
  font-weight: 900;
  letter-spacing: 0.02em;
}
.narrow {
  letter-spacing: -0.04em;
}

body,
html {
  background: black;
  color: white;
  font-family: "Lato", sans-serif;
  font-size: @fontSize;
  .minWidth(@maxWidth, {font-size: @maxFont;});
}
body {
  max-width: @maxWidth;
}
.text {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pointer {
  cursor: pointer;
}
//noinspection CssOverwrittenProperties
.noselect {
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

@section-devider: 20px;
.section-divider {
  background-image: url("tri.svg");
  background-size: cover @section-devider;
  height: @section-devider;
}
.pseudo-section-svg() {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  pointer-events: none;
}
a,
a:active,
a:visited,
a:link {
  color: white;
}
.section {
  position: relative;
  margin: 80px 0;
  padding: 20px 1rem;
  .text;

  &.white {
    background: white;
    color: black;
    & h1,
    & h2 {
      color: @violett;
    }
    &:before {
      .section-divider;
      .pseudo-section-svg;
      top: -@section-devider;
      transform: scaleY(-1);
    }
    &:after {
      .section-divider;
      .pseudo-section-svg;
      bottom: -@section-devider;
    }
  }
  &.black {
    background: black;
    color: #f5f5f5;
    b {
      font-weight: normal;
      color: @color_4;
    }
    i {
      font-weight: normal;
      color: @color_high_1;
      font-style: normal;
    }
  }
  &.header {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    height: 15rem;
    background-image: url("./tri_bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.header + &.white,
  &.header + &.black {
    margin-top: 0;
  }
}
.logo {
  position: absolute;
  width: 35vw;
}
.address {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.8rem;
  line-height: 1.4em;
  padding: 1rem;
  text-align: right;
  opacity: 0.8;
  a,
  span {
    display: block;
  }
}
.icon {
  position: absolute;
  bottom: 0rem;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  z-index: 2;
  &:after {
    position: absolute;
    bottom: -1.4em;
    left: 50%;
    width: 140px;
    max-width: 140px;
    margin-left: -70px;
    text-align: center;
    color: @color_0;
    font-weight: 900;
    font-size: 0.6rem;
  }
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-0.25rem);
  }
}
.insta {
  .icon;
  right: 1rem;
  background-image: url("./insta_icon.png");
  &:after {
    content: "Instagram";
  }
}
.facebook {
  .icon;
  right: calc(2rem + 70px);
  background-image: url("./facebook_icon.png");
  &:after {
    content: "Facebook";
  }
}
h1,
h2 {
  .noselect;
}
h1 {
  .bold;
  text-align: center;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  padding-bottom: 0.25rem;
}
h2 {
  font-weight: 400;
  text-align: center;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  .narrow;
  opacity: 0.7;
}

.info {
  font-size: 0.8rem;
  line-height: 1.4em;
  padding-bottom: 1rem;
}
.legend {
  margin-bottom: 1rem;
}
.legend span {
  font-weight: bold;
}
.legend span.fade {
  font-weight: normal;
  opacity: 0.15;
}

.poster {
  width: 80%;
  margin-left: 10%;
}

.column-text {
  font-size: 1rem;
  line-height: 1.4em;
  column-count: 2;
  padding: 0.25rem 0;
  .maxWidth(@stopPhone, {column-count: 1;});

  p {
    margin-top: 0;
    margin-bottom: 1.4rem;
  }
}

@import "./timetable.less";
@import "./teachers.less";
@import "./prices.less";
@import "./gallery.less";
@import "./dialog.less";

.imprint {
  text-align: center;
  white-space: pre-line;
  a {
    color: black;
  }
}
.haftung {
  margin-top: 1rem;
  text-align: left;
  font-size: 0.6rem;
  column-count: 2;
  opacity: 0.5;
}

video {
  width: 100%;
}

small {
  opacity: 0.25;
}
