table.timetable {
    @columnWidth: 100 / 7%;

    margin: 40px 0; 
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    font-size: 1rem;

    th {
        color: @color_0;
        font-weight: 900;
        width: @columnWidth;
        padding: 1rem;
    }

    tbody {
        font-size: 0.8rem; 
        
        tr:nth-child(odd) {
            background: fade(@color_0, 10%);
        }
        td {
            padding: 0.3rem 0.2rem;
            width: @columnWidth;
            max-width: @columnWidth;
            word-wrap: break-word;
            hyphens: auto;
            position: relative;
            vertical-align: top;
            // justify-content: space-between;
            cursor: default;
            .pseudo {
                position: absolute;
                color: white;
                font-weight: 900;
                border-radius: 0.25rem;
                top: -0.25rem;
                right: 0;
                font-size: 0.8rem;
                padding: 0.25em 0.4em;
                transform: rotate(20deg);
                z-index: 2;
            }
            &.neu .name:after {
                .pseudo;
                content: "Neu";
                text-transform: none;
                background: fade(@color_high_2, 80);
            }
            &.plan .time:after {
                .pseudo;
                content: "In Planung";
                background: fade(@color_high_3, 80);
                color: @color_2;
                transform: rotate(10deg);
            }
            &[level]:after {
                .pseudo;
                top: unset;
                bottom: 0;
                right: 0;
                content: attr(level);
                color: black;
                transform: none;
                text-transform: uppercase;
                font-size: 0.5rem;
                opacity: 0.25;
            }
            &:hover {
                background: fade(@color_0, 5);
            }
        }
        .time, .level {
            color: @color_2;
            padding: 0.2rem 0;
            .narrow;
        }
        .age {
            opacity: 0.25;
            font-size: 0.7em;
        }
        .name {
            text-transform: uppercase;
            .bold;
        }
    }
}